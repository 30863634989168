<template>
    <div class="bg-white d-flex flex-column justify-content-center ">
        <table class="text-dark table table-modal table-head-fixed">
            <thead class="thead">
                <tr>
                    <th>Chave</th>
                    <th>Valor</th>
                    <th>
                        <button 
                            class="btn btn-outline-primary btn-sm"
                            @click="includeItem()"
                        >
                            <i
                                class="fas fa-plus"
                                title="Adicionar"
                            ></i>
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr :key="key" v-for="(item, key) in request[showBodyPart ? 'body' : 'headers']">
                    <td>
                        <input
                            type="text"
                            v-model="request[showBodyPart ? 'body' : 'headers'][key].key"
                            class="form-control"
                            placeholder="Chave"
                        >
                    </td>
                    <td>
                        <input
                            type="text"
                            v-model="request[showBodyPart ? 'body' : 'headers'][key].value"
                            class="form-control"
                            placeholder="Valor"
                        >
                    </td>
                    <td>
                        <button
                            v-if="key"
                            class="btn btn-outline-danger btn-sm"
                            @click="removeItem(key)"
                        >
                            <i class="fas fa-minus" title="Remover"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    methods: {
        removeItem(key) {
            this.$delete(this.request[this.showBodyPart ? 'body' : 'headers'], key);
        },

        includeItem() {
            this.$set(this.request[this.showBodyPart ? 'body' : 'headers'], this.request[this.showBodyPart ? 'body' : 'headers'].length, {
                key: '',
                value: ''
            })
        }
    },
    props: {
        request: {
            event: String,
            url: String,
            method: String,
            headers: Object,
            body: Object,
            body_type: String
        },
        showBodyPart: Boolean
    },
}
</script>

<style>
</style>